import React from "react";
import { useFrame, useLoader } from "react-three-fiber";
import { TextureLoader } from "three";

function Basketball(props) {
  const mesh = React.useRef();
  const texture = useLoader(TextureLoader, "/texture/diffuse.jpg");
  const bump = useLoader(TextureLoader, "/texture/bump.jpg");

  useFrame(() => {
    mesh.current.rotation.x = mesh.current.rotation.y += 0.01;
  });

  return (
    <mesh ref={mesh} {...props}>
      <sphereBufferGeometry args={[1, 32, 32]} />
      <meshStandardMaterial attach="material" map={texture} bumpMap={bump} />
    </mesh>
  );
}

export default Basketball;
