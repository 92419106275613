import React from "react";
import ReactDOM from "react-dom";
import { Canvas, useFrame, useLoader } from "react-three-fiber";
import "./index.css";
import Basketball from "./Basketball";
import { usePlane, useSphere, Physics } from "use-cannon";
import { TextureLoader } from "three";

function Plane(props) {
  const [ref] = usePlane(() => ({ mass: 0, ...props }));
  return (
    <mesh ref={ref} receiveShadow>
      <planeBufferGeometry attach="geometry" args={[20, 20]} />
      <shadowMaterial attach="material" color="#171717" opacity={0.5} />
    </mesh>
  );
}

function Basketballs({ number }) {
  const [ref, api] = useSphere(() => ({
    mass: 1,
    args: 0.1,
    position: [Math.random() - 0.5, Math.random() * 2, Math.random() - 0.5],
  }));

  const texture = useLoader(TextureLoader, "/texture/diffuse.jpg");
  const bump = useLoader(TextureLoader, "/texture/bump.jpg");

  useFrame(() =>
    api
      .at(Math.floor(Math.random() * number))
      .position.set(0, Math.random() * 2, 0)
  );

  return (
    <instancedMesh
      receiveShadow
      castShadow
      ref={ref}
      args={[null, null, number]}
    >
      <sphereBufferGeometry args={[0.1, 32, 32]} />
      <meshStandardMaterial
        attach="material"
        map={texture}
        bumpMap={bump}
        bumpScale={0.1}
      />
    </instancedMesh>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <div class="hello">
      <span class="tillykke">Tillykke</span>
      <span class="chris">Chris!</span>
      <span class="what">Vi giver dig</span>
      <span class="x">1 valgfri</span>
      <span class="jersey">trøje.</span>
      <span class="ved">V.E.D</span>
      <span class="who">Phill, Sophie, Sebastian &amp; Nicklas</span>
    </div>
    <Canvas
      shadowMap
      colorManagement
      gl={{ alpha: false }}
      camera={{ position: [-1, 1, 2.5], fov: 50 }}
    >
      <color attach="background" args={["#f7d794"]} />
      <hemisphereLight intensity={0.35} />
      <spotLight
        position={[5, 5, 5]}
        angle={0.3}
        penumbra={1}
        intensity={2}
        castShadow
        shadow-mapSize-width={256}
        shadow-mapSize-height={256}
      />
      <React.Suspense fallback={null}>
        <Physics>
          <Plane rotation={[-Math.PI / 2, 0, 0]} />
          <Basketballs number={200} />
        </Physics>
      </React.Suspense>
    </Canvas>
  </React.StrictMode>,
  document.getElementById("root")
);
